import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
/* import Img from 'gatsby-image' */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Hr from '../components/Hr'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import MeuIp from '../components/MeuIp'
import ContainedButton from '../components/ContainedButton'

import { Container } from '../components/styles/Layout.styles'
import MuiField from '../components/MuiField'
import MuiSelect from '../components/Dropdown'
// import { ipMask, ipPipe } from './../utils/masks'
import { 
  styledTools,
  header,
  meuIp,
  btnMeuIp
} from '../components/styles/Tools.styles'
import { btnContainedLink, btnLargeWhiteSmall } from '../components/styles/ContainedButton.styles'

export const PageTemplate = ({
  data
}) => {
  const [Country, setCountry] = useState('')
  const [Teste, setTeste] = useState('')
  const [IpDestino, setIpDestino] = useState('')
  const [LookingGlassResult, setLookingGlassResult] = useState('')
  const handleInputChange = (event) => {
    event.persist()
    console.log(event)
    setIpDestino(event.target.value)
  }
  const mostraAqui = (event) => {
    event.preventDefault()
    console.log('MostraAqui')
    console.log(Country)
    console.log(Teste)
    console.log(IpDestino)
    const response = fetch (`https://www.hostdime.com.br/site/network-tools.php?url=http%3A%2F%2F66.7.205.190%2Flg%2Fajax.php%3Fcmd%3Dmtr%26host%3D8.8.8.8&callback=`, {mode: 'no-cors'})
    console.log(response)
    setLookingGlassResult(response.ok)
    // // .then(function(res){
    // //   return res
    // // })
    // .then(function(response){
    //   console.log(response)
    //   return setLookingGlassResult(response.data)
    // })
    // .catch(function(erro){
    //   console.log('ERRO', erro)
    // })
  }
  return (
    <Fragment>
      <section css={styledTools}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            pb={50}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>Ferramentas</span><br />
                Teste sua velocidade de conexão com nossos data centers globais.
              </H4>
              <Paragraph>Acompanhe o roteamento e comportamento da rede que se origina de nossas redes remotas e teste sua velocidade de conexão com nossos data centers globais.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section
        style={{
          backgroundColor: '#f9fafa',
          padding: '50px 0'
        }}
      >
        <Container>
          <Row
            flexDirection={['column']}
            css={header}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <H5>Ferramentas de Rede</H5>
              <Paragraph>Nós estamos comprometidos em fornecer aos nossos clientes todo o apoio necessário para gerenciar de maneira rápida e fácil todos os aspectos do seu servidor. Por isso, desenvolvemos ferramentas criadas especialmente para ajudar você a gerenciar seus servidores.</Paragraph>
            </Column>
            <Div
              flexDirection={['column', 'row']}
              justifyContent={['center']}
              alignItems={['center']}
            >
              <Column>
                <div
                  style={{
                    width: 170
                  }}
                >
                  <ContainedButton
                    type='btnContainedWhiteSmall'
                    Link='/internal-proxy'
                  >
                    PROXY TOOLS
                  </ContainedButton>
                </div>
              </Column>
              <Column>
                <a
                  href='http://dns.hostdime.com/'
                  css={btnLargeWhiteSmall}
                  style={{
                    width: 170
                  }}
                >
                  dns tools
                </a>
              </Column>
            </Div>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row
            flexDirection={['column']}
            css={header}
            mt={50}
            mb={50}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <H5>Looking Glass</H5>
              <Paragraph>Esta ferramenta provê informações de rede relativas ao roteamento de backbone da nossa infraestrutura. Devido à natureza destas requisições serem de baixa prioridade em nossos roteadores de rede, o serviço não é apropriado para medir SLA (Service Legal Agreements). O Looking Glass deve ser utilizado apenas para verificar informações de rota.</Paragraph>
            </Column>
            <Column
              mb={50}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiSelect
                  options={[
                    {label: 'USA - Orlando, Florida', value: '66.7.205.190'},
                      {label: 'USA - Los Angeles, California', value: '198.136.57.228'},
                      {label: 'Brazil - Joao Pessoa', value: '187.45.177.101'},
                      {label: 'Brazil - São Paulo', value: '177.234.158.69'},
                      {label: 'India - New Delhi', value: '103.13.242.3'},
                      {label: 'United Kingdom - London', value: '212.18.231.178'},
                      {label: 'Netherlands - Rotterdam', value: '212.18.230.134'},
                      {label: 'Mexico - Guadalajara', value: '201.131.127.150'},
                      {label: 'Colombia - Bogota', value: '138.121.200.45'},
                      {label: 'Hong Kong - Chai Wan', value: '103.13.240.2'}
                  ]}
                  label='Localização'
                  id='stCountry '
                  name='stCountry'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiSelect
                  options={[
                    {label: 'host', value: 'host'},
                    {label: 'mtr', value: 'mtr'},
                    {label: 'ping', value: 'ping'},
                    {label: 'traceroute', value: 'traceroute'}
                  ]}
                  id="stFilesize" 
                  name="stFilesize"
                  label='Tipo de teste'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiField
                  variant='outlined'
                  label='IP de Destino'
                  placeholder='198.49.64.128'
                  disable
                  style={{
                    maxWidth: 328,
                    marginBottom: 30
                  }}
                />
                { false && <form action='' onSubmit={mostraAqui}>
                  <MuiSelect
                    options={[
                      {label: 'USA - Orlando, Florida', value: '66.7.205.190'},
                      {label: 'USA - Los Angeles, California', value: '198.136.57.228'},
                      {label: 'Brazil - Joao Pessoa', value: '187.45.177.101'},
                      {label: 'Brazil - São Paulo', value: '177.234.158.69'},
                      {label: 'India - New Delhi', value: '103.13.242.3'},
                      {label: 'United Kingdom - London', value: '212.18.231.178'},
                      {label: 'Netherlands - Rotterdam', value: '212.18.230.134'},
                      {label: 'Mexico - Guadalajara', value: '201.131.127.150'},
                      {label: 'Colombia - Bogota', value: '138.121.200.45'},
                      {label: 'Hong Kong - Chai Wan', value: '103.13.240.2'}
                    ]}
                    label='Localização'
                    id='Country'
                    isClearable={false}
                    onChange={(e) => setCountry(e.value)}
                    style={{
                      maxWidth: 328,
                      marginBottom: 20,
                      textAlign: 'left'
                    }}
                  />
                  <MuiSelect
                    options={[
                      {label: 'host', value: 'host'},
                      {label: 'mtr', value: 'mtr'},
                      {label: 'ping', value: 'ping'},
                      {label: 'traceroute', value: 'traceroute'}
                    ]}
                    label='Tipo de teste'
                    id="Teste"
                    isClearable={false}
                    onChange={(e) => setTeste(e.value)}
                    style={{
                      maxWidth: 328,
                      marginBottom: 20,
                      textAlign: 'left'
                    }}
                  />
                  <input 
                    type='text' 
                    id='IpDestino' 
                    name='IpDestino'  
                    value={IpDestino}
                    onChange={handleInputChange}                     
                  />
                  {/* <input 
                    id='IpDestino' 
                    type='text'
                    onChange={(e) => setIpDestino(e.value)}
                    value={IpDestino}
                  /> */}
                  {/* <MuiField
                    variant='outlined'
                    id='IpDestino'
                    label='IP de Destino'
                    placeholder='IP de Destino'
                    mask={ipMask}
                    pipe={ipPipe}
                    onChange={(e) => console.log(e)}
                    style={{
                      maxWidth: 328,
                      marginBottom: 30
                    }}
                  /> */}
                  <Div
                    maxWidth={['100%', 680]}
                    style={{
                      marginBottom: 30
                    }}
                  >
                    <div 
                      style={{ 
                        border: 'solid 1px rgba(0, 0, 0, 0.32)',
                        height: 300,
                        borderRadius: 20,
                        padding: 24,
                        textAlign: 'left',
                        color: '#666666',
                        fontWeight: 600
                      }}
                    >
                      {LookingGlassResult}
                    </div>
                  </Div>
                  <button 
                    type='submit'
                    value="Run Test"
                    style={{
                      width: 160
                    }}
                  >
                    Teste
                  </button>
                </form>}
              </div>
            </Column>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={[328, '100%', 680, 813]}
              style={{
                opacity: 0.5,
                margin: '0 auto'
              }}
            />
            <Column
              width={[1]}
              pt={40}
              pb={30}
            >
              <H5>Teste de Velocidade</H5>
              <Paragraph>Tenha certeza de que seus visitantes estão tendo o tempo de resposta mais rápido possível para o seu website hospedando-o em localizações estratégicas para seu público-alvo. Utilize o formulário abaixo para fazer o download de um arquivo de teste de qualquer localização em que temos data centers ou servidores.</Paragraph>
            </Column>
            <Column
              mb={50}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiSelect
                  options={[
                    {label: 'USA - Orlando, Florida', value: 'us', dataIp: '66.7.205.190'},
                    {label: 'USA - Los Angeles, California', value: 'us', dataIp: '198.136.57.228'},
                    {label: 'Brazil - Joao Pessoa', value: 'br', dataIp: '187.45.177.101'},
                    {label: 'Brazil - São Paulo', value: 'br', dataIp: '177.234.158.69'},
                    {label: 'India - New Delhi', value: 'in', dataIp: '103.13.242.3'},
                    {label: 'United Kingdom - London', value: 'uk', dataIp: '212.18.224.182'},
                    {label: 'Netherlands - Rotterdam', value: 'nl', dataIp: '212.18.230.134'},
                    {label: 'Mexico - Guadalajara', value: 'mx', dataIp: '201.131.127.150'},
                    {label: 'Colombia - Bogota', value: 'co', dataIp: '138.121.200.45'},
                    {label: 'Hong Kong - Chai Wan', value: 'hk', dataIp: '103.13.240.2'}
                  ]}
                  label='Localização'
                  id='stCountry '
                  name='stCountry'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiSelect
                  options={[
                    {label: '128MB', value: '128MB', dataFile:'128MB.test'},
                    {label: '512MB', value: '512MB', dataFile:'512MB.test'},
                    {label: '1GB', value: '1024MB', dataFile:'1024B.test'},
                    {label: '2GB', value: '2048MB', dataFile:'2048MB.test'}
                  ]}
                  id="stFilesize" 
                  name="stFilesize"
                  label='Tamanho do arquivo'
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <MuiField
                  variant='outlined'
                  label='IP de Destino'
                  placeholder='198.49.64.128'
                  disable
                  style={{
                    maxWidth: 328,
                    marginBottom: 30
                  }}
                />
                <div
                  css={btnContainedLink}
                  style={{
                    width: 220
                  }}
                >
                  download file
                </div>
              </div>
            </Column>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={[328, '100%', 680, 813]}
              style={{
                opacity: 0.5,
                margin: '0 auto'
              }}
            />
            <Column
              width={[1]}
              pt={40}
            >
              <H5>Meu IP</H5>
              <Paragraph>Veja aqui o seu IP de conexão com a internet que está atribuído ao seu computador ou dispositivo utilizado para acesso a internet.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row
            flexDirection={['column']}
            css={meuIp}
          >
            <Column
              width={[1]}
              mt={30}
              mb={50}
            >
              <H5>O seu endereço IP Atual é:</H5>
              <Div 
                css={btnMeuIp}
                style={{
                  margin: '0 auto'
                }}
              >
                <MeuIp />
              </Div>
              <Paragraph>Por vezes existem alterações nas zonas DNS e é necessário apagar a informação guardada de forma a obter os resultados atualizados corretamente. Para resolver esta situação, poderá simplesmente limpar o cache dos DNS, fazendo FlushDNS.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Ferramentas | DNS, Proxy Tools, Looking Glass e mais'
        description='Teste o tempo de resposta do seu website e dos nossos servidores, acesse ferramentas DNS, Proxy Tools e Looking Glass. Confira!'
      />
      <PageTemplate
        title='Ferramentas'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
